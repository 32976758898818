// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"modal\" aria-hidden=\"true\">\n  <div class=\"modal-dialog "
    + alias4(((helper = (helper = lookupProperty(helpers,"sizeClass") || (depth0 != null ? lookupProperty(depth0,"sizeClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizeClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":40}}}) : helper)))
    + "\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>\n        <h4 class=\"modal-title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"titleText") || (depth0 != null ? lookupProperty(depth0,"titleText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleText","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":47}}}) : helper))) != null ? stack1 : "")
    + "</h4>\n      </div>\n      <div class=\"modal-body\">\n        <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"bodyText") || (depth0 != null ? lookupProperty(depth0,"bodyText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bodyText","hash":{},"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":25}}}) : helper))) != null ? stack1 : "")
    + "</p>\n      </div>\n      <div class=\"modal-footer\">\n        <button class=\"button\" id=\"modal-submit-button\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"submitBtnText") || (depth0 != null ? lookupProperty(depth0,"submitBtnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"submitBtnText","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":73}}}) : helper)))
    + "</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
