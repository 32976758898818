'use strict';

const $ = require('jquery');

module.exports = function() {
  $('body').on('click', '[data-toggle="dropdown"]', (e) => {
    e.preventDefault();
    $(e.currentTarget).closest('.dropdown').toggleClass('dropdown--visible');
  });

  $('body').on('click', () => {
    $('.dropdown--visible').removeClass('dropdown--visible');
  });

  $('body').on('click', '.dropdown', (e) => {
    e.stopPropagation();
  }); 
};
