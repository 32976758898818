'use strict';

const $ = require('jquery');

module.exports = function() {
  $('body').on('click', '[data-analytics-click]', (e) => {
  	// Only relevant for links right now.
    if($el.attr('href')) {
      e.preventDefault();
    }
    const $el = $(e.currentTarget);
    ga('send', 'event', $el.attr('data-analytics-click'), 'click');
    if($el.attr('href')) {
      location.href = $el.attr('href');
    }
  });
};
