// reset book demo form 
window.showModal=function(){
  $(".alert-info").hide();
  $('#name').val('');
  $('#phone').val('');
  $('#email').val('');
  var element = document.getElementById("bookdemo");
  element.classList.remove("was-validated");
};

// submit book a demo form with validation
(function() {
  'use strict';
  window.addEventListener('load', function() {
    // Get the forms we want to add validation styles to
    var forms = document.getElementsByClassName('bookdemo');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
       // alert(form.classList);
       form.classList.add('was-validated');
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        
       else {

          var name = $('#name').val();
          var phone = $('#phone').val();
          var email = $('#email').val();
          $.ajax({
              type: "POST",
              url: '/book-demo',
              cache: false,
              async:true,
              data: {name:name, email:email, phone:phone},
              headers: {
                'X-CSRF-TOKEN': $('#_token').val()
             },
              success: function(result) {
                if(result.status == 'error') {
                 
                  $('#msg').html('<div class="alert alert-danger col-12 alert-dismissible" ><button type="button" class="close" data-dismiss="alert">&times;</button>' + result.message + '</div>');
                } else {
                form.classList.remove('was-validated');
               document.getElementById("bookdemo").reset();
                $('#msgpopup').html('<div class="alert alert-info col-12 alert-dismissible" ><button type="button" class="close" data-dismiss="alert">&times;</button>' + result.message + '</div>');
                }
              }
          });        }

       
        event.preventDefault();
        event.stopPropagation();
            
      }, false);
    });
  }, false);
})();

// submit contact us form with validation
(function() {
  'use strict';
  window.addEventListener('load', function() {
    // Get the forms we want to add validation styles to
    var forms = document.getElementsByClassName('contactus');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
       // alert(form.classList);
       form.classList.add('was-validated');
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        
       else {
          var name = $('#contactName').val();
          var email = $('#contactEmail').val();
          var message = $('#message').val();
          
          $.ajax({
              type: "POST",
              url: '/contact-us',
              cache: false,
              async:true,
              data: {name:name, email:email, message:message},
              headers: {
                'X-CSRF-TOKEN': $('#_token').val()
             },
              success: function(result) {
                if(result.status == 'error') {
                 
                  $('#msg').html('<div class="alert alert-danger col-12 alert-dismissible" ><button type="button" class="close" data-dismiss="alert">&times;</button>' + result.message + '</div>');
                } else {
                form.classList.remove('was-validated');
                document.getElementById("contactus").reset();
                $('#msg').html('<div class="alert alert-info col-12 alert-dismissible" ><button type="button" class="close" data-dismiss="alert">&times;</button>' + result.message + '</div>');
                }
              }
          }); 
        }
       
        event.preventDefault();
        event.stopPropagation();
            
      }, false);
    });
  }, false);
})();
 
$(document).ready(function () {
  $('.btn-link').click(function () {
    $(this).toggleClass('active');
    $(this).parents('.card').siblings('.card').find('.btn-link').removeClass('active');
  });

  $('.navbar-toggler').on('click', function(){
    $(this).find('i').toggleClass('fa-times');
  });
});
 