'use strict';

const $ = require('jquery');
const dropdowns = require('./libs/dropdowns');
const modals = require('./libs/modals');
const transitions = require('./libs/transitions');
const analytics = require('./analytics');
const Modal = require('./modal');
require('babel-polyfill');
require('./custom');
$(document).ready(function() {

  transitions();
  modals();
  analytics();
  dropdowns();
  
  // TEMPORARY CODE
  $('#mobile-change-language').on('click', () => {
    new Modal({
      titleText: 'Change language',
      bodyText: `<select class="select" id="modal-language-select" name="language">
        <option value="en">English</option>
        <option value="se">Svenska</option>
      </select>`,
      submitBtnText: 'OK',
      onShown: () => $('#modal-language-select').focus()
    });
    $( "#modal-submit-button" ).click(function() {
      window.location = "/" + $( "#modal-language-select" ).val();
    });
  });

  // faq questions
  $('.faq').on('click', '.faq__question', (e) => {
    let $question = $(e.currentTarget);
    $question.toggleClass('faq__question--expanded');
    let $answer = $question.next();
    if ($question.hasClass('faq__question--expanded'))
      $answer.height($answer[0].scrollHeight);
    else
      $answer.height(0);
  });

});
