'use strict';

const $ = require('jquery');
const template = require('./templates/modal.hbs');

module.exports = (function() {

  /**
   * Trigger this modal by calling "new Modal(options, callback)"
   * or "Modal(options, callback)" if noob.
   *
   * Valid options:
   * (titleText <String>): The text at the title bar of the modal
   * (bodyText <String>): The text in the body of the modal
   * (submitBtnText <String>): Text in the submit button of the modal
   * (sizeClass <String>): Determines the size of the modal: ['modal-sm' (small, default), '' (medium), 'modal-lg' (large)]
   * (onShown <Function>): Function called when modal is opened.
   */
  function Modal(options, callback) { // {}, [function] -> void
    if (!(this instanceof Modal)) return new this.Modal(options, callback);

    const defaultOptions = {
      titleText: 'Placeholder title!',
      bodyText: 'Placeholder body.',
      submitBtnText: 'Close',
      sizeClass: 'modal-sm',
      onShown: () => $('#modal-submit-button').focus()
    };
    options = $.extend(defaultOptions, options);
    $('#modal-container').html(template(options));

    const $modal = $('#modal');
    $modal.one('click', '#modal-submit-button', () => $modal.modal('hide'));
    if (typeof callback !== 'undefined') $modal.one('hidden.bs.modal', () => callback());
    $modal.one('shown.bs.modal', () => options.onShown());
    $modal.modal();
  }

  return Modal;
})();
